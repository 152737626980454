const env = {
    url: 'https://api.staging.planetary.blue/api'
    // url: 'http://localhost:8000/api'
    // url: 'https://api.blueplanet.leentechdev.com/api'

}

export async function excecuteRequest(endpoint, payload=false) {
    try {
        // const response = await fetch(`${env.url}${endpoint}`, {
        //   method: payload? 'POST' : 'GET',

        //   headers: payload ? {
        //     'Content-Type': 'application/json',
        //   } : undefined,
        //   body: payload ? JSON.stringify(payload) : undefined,
        // });

        // const data = await response.json();
        // return data;

        const isFormData = payload instanceof FormData;

        const response = await fetch(`${env.url}${endpoint}`, {
          method: payload ? 'POST' : 'GET',
          headers: isFormData
            ? undefined
            : {
                'Content-Type': 'application/json',
              },
          body: payload? isFormData ? payload : JSON.stringify(payload) : undefined,
        });
    
        const data = await response.json();
        return data;

      } catch (error) {
        console.error('error', error);
        throw error;
      }
  }