import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import './App.css'
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useRef, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { excecuteRequest } from './request';
import { Button, Divider, LinearProgress, MenuItem, Select } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { createTheme } from '@mui/system';
import { ThemeProvider } from '@mui/system';
import { useLocation } from 'react-router-dom';


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userParam = queryParams.get('usrxcv76ennd');

  const [open, setOpen] = React.useState(true);

  const theme = useTheme();

  const updatedTheme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: '#193655',
        light: '#e1e5e7',
        dark: '#0e1f2f',
        200: '#96a8b7',
        800: '#000b1a'
      },
    },
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [panel, setPanel] = useState("chatbot");
  const [convos, setConvos] = useState([]);
  const [selectedConvo, setSelectedConvo] = useState(false);
  const [messages, setMessages] = useState([]);
  const [numberOfConvos, setnumberOfConvos] = useState(0);
  const [loading, setLoading] = useState(true);

  

  const changePanel = (newPanel) => {
    console.log(newPanel)
    setPanel(newPanel);
  };

  async function fetchMessages(){
    if(selectedConvo){
      setLoading(true)
      setMessages([])
      let response = await excecuteRequest('/messages', {"convo_id": selectedConvo.id})
      setMessages(response.data)    
      setLoading(false)
    }
  }

  async function fetchConversations (){
    console.log(userParam)
    setLoading(true)
    let response = await excecuteRequest('/list-conversation', {"user":userParam})
    console.log(response.length)
    if (response.length > 0) {
      setConvos(response);
      setSelectedConvo(response[0]);
    }

    setLoading(false)


  }

  useEffect(() => {
    changePanel('chatbot')
  }, [numberOfConvos]);

  useEffect(() => {
    fetchConversations()
  }, [numberOfConvos]);

  useEffect(() => {
    fetchMessages()
  }, [selectedConvo]);

  return (
    <ThemeProvider theme={updatedTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <div style={{color: 'white'}} >
              {selectedConvo && selectedConvo.name}
            </div>

          </Toolbar>
          {loading && 
          <Box sx={{ width: '100%' }}>
              <LinearProgress />
          </Box>}
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>

          <List>

          <ListItem disablePadding>
            <ListItemButton onClick={() => changePanel('add')}>
              <ListItemIcon><AddIcon/></ListItemIcon>
              <ListItemText primary={"New Conversation"} />
            </ListItemButton>
          </ListItem>

          <Divider/>

          {convos.map((convo, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton 
              selected = {selectedConvo.id === convo.id}
              onClick={() => {
                changePanel('chatbot')
                setSelectedConvo(convo)
              }}>
              <ListItemIcon>{/* Add your icon component here */}</ListItemIcon>
              <ListItemText primary={convo.name} />
            </ListItemButton>
          </ListItem>
        ))}


          </List>

          
        </Drawer>
        <MyPanel panel = {panel} open = {open} setSelectedConvo = {setSelectedConvo} setnumberOfConvos = {setnumberOfConvos} messages = {messages} setMessages = {setMessages} convoId={selectedConvo && selectedConvo.id ? selectedConvo.id : null} setLoading={setLoading} loading = {loading}/>
      </Box>
    </ThemeProvider>
  );

}

function MyPanel(props) {    
  if (props.panel === "add") return (<SparePanel open = {props.open}  setSelectedConvo = {props.setSelectedConvo} setnumberOfConvos = {props.setnumberOfConvos}  setLoading={props.setLoading} loading = {props.loading}/>)
  if (props.panel === "chatbot") return (<ChatbotPanel open = {props.open} messages = {props.messages} setMessages = {props.setMessages} convoId = {props.convoId} setLoading={props.setLoading} loading = {props.loading}/>)
} 

function SparePanel(props) {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userParam = queryParams.get('usrxcv76ennd');

  const [convoName, setConvoName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [botError, setBotError] = useState(true);

  const [bot, setBot] = React.useState('');
  const [bots, setBots] = React.useState(false);

  const handleChange = (event) => {
    setBot(event.target.value);
  };

  useEffect(() => {
    setNameError(false)
    setBotError(false)
  }, [convoName, botError])

  const fetchBots = async () => {
    props.setLoading(true)
    let response = await excecuteRequest('/bots?paginated=false')
    setBots(response)
    setBot(response[0].id);
    props.setLoading(false)
  }

  useEffect(()=>{
    fetchBots()
    console.log(userParam)
    // eslint-disable-next-line 
  }, [])

  const createConvo = async () => {
    props.setLoading(true)
    let wrap = {"name" : convoName, "bot" : bot , "user" : userParam}
    let response = await excecuteRequest('/conversation', wrap)
    props.setnumberOfConvos(Math.random())
    props.setSelectedConvo(response.id)
    props.setLoading(false)
  }

  const theme = useTheme();

  const updatedTheme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: '#193655',
        light: '#e1e5e7',
        dark: '#0e1f2f',
        200: '#96a8b7',
        800: '#000b1a'
      },
    },
  };

  function isOnlySpaces(input) {
    // Remove all spaces from the input string
    const stringWithoutSpaces = input.replace(/\s/g, '');
  
    // Check if the resulting string is empty
    return stringWithoutSpaces.length === 0;
  }

  return (
    <ThemeProvider theme={updatedTheme}>
      <Main open={props.open}>
        <DrawerHeader />
        <Container maxWidth="md">
          <FormControl variant="outlined" fullWidth>
              <InputLabel error={nameError} htmlFor="standard-adornment-password">Conversation Name * </InputLabel>
              <OutlinedInput
                className='container'
                id="standard-adornment-password"
                name = "message"
                type="text"
                error = {nameError}
                sx={{borderRadius: '15px'}}
                onChange = {(event) => {setConvoName(event.target.value)}}
                onBlur={(event) => {if (event.target.value === '' || isOnlySpaces(event.target.value)) setNameError(true) }}
                label="Conversation Name"
              />

              {
               
                nameError && 

                <Typography variant='caption' color="error">
                  Conversation name is required
                </Typography>
              }


          </FormControl>
          <br/>
          <br/>
          <FormControl fullWidth>
          <InputLabel error = {botError} id="demo-simple-select-helper-label">Bot</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            error={botError}
            value={bot}
            label="Age"
            onChange={handleChange}
            sx={{ borderRadius: '15px' }}
            onBlur={event => {
              if (event.target.value === '') setBotError(true);
            }}
          >
            {bots &&
              bots.map(bot => (
                <MenuItem key={bot.id} value={bot.id}>
                  {bot.name}
                </MenuItem>
              ))}
          </Select>


            {
                
              botError && 

              <Typography variant='caption' color="error">
                Bot is required
              </Typography>
            }

          </FormControl>

          <br/><br/>
          <Button 
            variant="contained" 
            size="large"
            disabled={props.loading}
            onClick = {async () => {
              if (isOnlySpaces(convoName)){
                setNameError(true)
              } else {
                createConvo()
              }           

              if (isOnlySpaces(bot)){
                setBotError(true)
              }

            }}>

              <span style={{color: "white", textTransform: 'capitalize'}}> create </span>

          </Button>

        </Container>
      </Main>
    </ThemeProvider>
  )
} 

function ChatbotPanel(props) {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userParam = queryParams.get('usrxcv76ennd');

  const maxTokens = 4000
  const tokens = 300

  const [tokensconsumed, setConsumption] = useState(0)

  const handletokens = (tokens) => {
    setConsumption(tokens)
  }

  // const [messages, setMessages] = useState([props.messages]);

  const setMessages = props.setMessages
  let messages = props.messages

  async function handleSendMessage(event) {
    const newMessage = event;
    let wrappedMessage = {"role": "user", "content": newMessage}
    const newMessagesArray = [...messages, wrappedMessage];
    setMessages(newMessagesArray);
    
    await handleQuery(newMessage, newMessagesArray)

    handleLoaderVisibility(true)    
    setVaue('');
   };

   const handleQuery = (prompt, messagesArray) => {
      // fetch('http://localhost:8000/api/create-message', {
      fetch('https://api.staging.planetary.blue/api/create-message', {
        method: 'POST',
        body: JSON.stringify({
          "convo_id": props.convoId, 
          "folder": 1,
          "history": messages, 
          "content": prompt,
          "temperature": 0.1, 
          "max_tokens": tokens 
        }),
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization' : 'Bearer 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',
          // 'OpenAI-Organization': 'org-12VRDOrOWPhcwWbMMzL6wWi7'
        }
      })
      .then(response => response.json())
      .then(data => {
        // let message = data.choices[0].text.replace("[prompt]", "").replace('[end prompt]' , '')
        // if (message.startsWith('\n\n')) { message = message.substring(2)}
        // if(message  == '.') { message = 'I\'m glad I could help you today. If you have any more questions, don\'t hesitate to ask. Have a great day!' }
        // let message = data.content
        // let wrappedMessage = {"role": "assistant", "content" : }
        let newMessagesArray = [...messagesArray, data[0]];
        // newMessagesArray = [...newMessagesArray, data[0]];
        setMessages(newMessagesArray);
        handleLoaderVisibility(false)
        console.log(newMessagesArray)
        handletokens(data.total_tokens ? data.total_tokens : 0)
      })
      .catch(error => console.log(error));
   }

   const [val, setVaue] = useState('')
   
   const handleMessageValue = (data) => {
     setVaue(data)
   }

   const [loader, showHideLoader] = useState(false)

   const handleLoaderVisibility = (val) => {
    showHideLoader(val)
    // console.log(loader)
   }

   const messagesPanel = useRef(null);

   useEffect(() => {
    const container = messagesPanel.current;
    container.scrollTop = container.scrollHeight;
  }, [messages]);

  return (
    <Main open={props.open}  sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: "0"}}>
      <DrawerHeader />
      <div ref = {messagesPanel} className = "container" style = {{height: "85%" , overflowY: "scroll", }}>  
        <Stack spacing={0}>
          {messages.map(function(item, index) {
                return (
                <div key = {index} style={{backgroundColor: index % 2 == 0 ? 'white' : '#dee0df'}}>
                  <Container maxWidth="md">
                    <div style={{display: 'flex' , flexDirection: 'row', padding: "30px 0 30px 0"}}>
                      {/* <div className='profile_icon' style = {{width: "7%", color: '#1976d2', minWidth: '40px'}}> {index % 2 == 0 ? <img src = {ficon} alt = "chat" style={{height: "30px"}}/> : <PersonIcon/>} </div> */}
                      <div  style = {{width: "93%"}}>
                        <div>

                        <React.Fragment key={index}>
                          <Typography style={{ fontFamily: 'Open Sans', whiteSpace: 'pre-line' }}>
                            {item.content}
                          </Typography>
                          <br />
                        </React.Fragment>

                        </div>
                      </div>
                    </div>    
                  </Container>               
                </div>                  
                )
              })
          }
        </Stack>
        { loader &&  <div style={{padding: "20px" , display: 'flex', justifyContent: 'center'}}>
            <CircularProgress size = {30} color="primary" />
          </div> 
        }
        
      </div>
      <div style = {{width: "100%", padding: "30px 0 30px 0"}}> 
        <Container maxWidth="md">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="standard-adornment-password">Query</InputLabel>
            <OutlinedInput
              className='container'
              id="standard-adornment-password"
              name = "message"
              type="text"
              onChange = {(event) => {handleMessageValue(event.target.value)}}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  handleSendMessage(val)
                }
              }}
              value = {val}
              
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="send"
                    onClick={() => {handleSendMessage(val)}}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Prompt"
              fullWidth
              multiline
              maxRows = '5'
              disabled = {loader || tokensconsumed > maxTokens}
            />
          </FormControl>
        </Container>
      </div>   
      <Snackbar open={tokensconsumed > maxTokens}>
        <MuiAlert  severity="warning" variant = "filled">Oops, it looks like this conversation has exceeded the limit. To start a new one, please refresh the page.</MuiAlert >
      </Snackbar>
    </Main>
  )
} 